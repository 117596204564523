/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { useCurrentOrderCartItems } from '../../../../hooks/useCurrentOrderCartItems';
import usePreventNavigation from '../../../../hooks/usePreventNavigation';
import RegularProductConfig from '../regular/RegularProductConfig';
import cartUtils from '../../../../utils/cart';

const IncentivesConfig = ({ history }) => {
  // prevent user from navigating away from this page
  usePreventNavigation(history, '/v3/incentives/config');

  const cartItems = useCurrentOrderCartItems();
  const dispatch = useDispatch();
  const {
    v3Order: { orders },
    shoot: { incentiveProducts },
  } = useSelector((state) => state);

  let incentivesToConfig = cartUtils.eligibleIncentivesForOrder(
    cartItems,
    incentiveProducts,
  );

  if (incentivesToConfig?.length === 0) {
    incentivesToConfig = cartUtils.eligibleIncentivesForCart(
      orders,
      incentiveProducts,
    );
  }

  // if no incentives to config, push to offerings
  if (incentivesToConfig && incentivesToConfig.length === 0) {
    dispatch(push('/v3/offers'));
  }

  let incentiveProduct = null;

  if (incentivesToConfig && incentivesToConfig.length > 0) {
    incentiveProduct = incentivesToConfig[0];
  }

  // TODO: log error here and show generic error message
  if (!incentiveProduct) {
    <div>
      <p>There was an error</p>
      <p>Error code: E211 - no incentive product to display</p>
    </div>;
  }

  return (
    <RegularProductConfig
      product={incentiveProduct}
      incentiveProducts={incentiveProducts}
    />
  );
};

IncentivesConfig.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    block: PropTypes.func.isRequired,
  }).isRequired,
};

export default IncentivesConfig;
