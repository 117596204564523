import React from 'react';
import styled from 'styled-components';
import ChildItemBackgroundPose from './ChildItemBackgroundPose';

const ProductName = styled.p`
  font-weight: normal;
  font-size: 14px;
  margin-bottom: 6px;
`;

const Header = styled.h6`
  font-size: 14px;
  font-style: italic;
  font-weight: normal;
  margin-bottom: 16px;
`;

const ProductWithChildElements = ({ cartItem }) => (
  <>
    <Header>components</Header>

    {cartItem.children.map((item, index) => (
      <div key={index}>
        <ProductName>{item.productName}</ProductName>
        <ChildItemBackgroundPose
          item={item}
          isLastItem={index === cartItem.children.length - 1}
        />
      </div>
    ))}
  </>
);

export default ProductWithChildElements;
