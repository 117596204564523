import {
  GET_SHOOT_DATA,
  FORMAT_AND_SET_BACKGROUND_OPTIONS,
  FORMAT_AND_SET_INCENTIVE_PRODUCTS,
  SET_RETOUCHING_PRODUCT,
  SET_RETOUCHING_PRODUCTS,
  SET_PERSONALIZATION_PRODUCT,
  SET_INTERSTITIAL_PRODUCTS,
  SET_PRE_CART_OFFER_PRODUCTS,
  SET_TRIGGERS_PRE_CART_OFFER_PRODUCTS,
} from '../actions/action_shoot';

const INITIAL_STATE = {
  backgroundOptions: [],
  event_id: null,
  dp2_job_id: null,
  formattedBackgroundOptions: {},
  formattedIncentiveProducts: {},
  incentiveProducts: [],
  interstitialProducts: [],
  preCartOfferProducts: [],
  personalization: {},
  personalizationAppliedToAllPersonalizableProducts: false,
  photoEnhancements: [],
  postpayRequiresSameValuePersonalization: false,
  preCartOfferProducts: [],
  prepayRequiresSameValuePersonalization: false,
  shoot: {},
  shootPricing: {},
  shootProducts: [],
  triggersPreCartOfferProducts: [],
  retouchingProduct: null,
  yearbookSelectionDeadline: null,
  yearbookSelectionInstructions: null,
  yearbookBackgroundOptions: [],
  yearbookBackgroundSelectionType: null,
  yearbookFixedBackground: null,
};

export default function (state = INITIAL_STATE, action) {
  Object.freeze(state);

  switch (action.type) {
    case GET_SHOOT_DATA:
      if (action.payload.data) {
        if (action.payload.data.errors) {
          return state;
        }

        console.log('%cEVENT DATA', 'color: pink', action.payload.data);

        // TODO: ensure we set a default state for the below properties
        return {
          ...state,
          backgroundOptions: action.payload.data.background_options,
          containsBackgroundCategories:
            action.payload.data.contains_background_categories,
          dp2JobId: action.payload.data.shoot.dp2_job_id,
          eventId: action.payload.data.shoot.event_id,
          incentiveProducts: action.payload.data.incentive_products,
          isGreenScreen: action.payload.data.shoot.is_green_screen,
          isPrepay: action.payload.data.shoot.is_prepay,
          prepayPersonalizationOfferType:
            action.payload.data.shoot.prepay_personalization_offer_type,
          personalization: action.payload.data.personalization,
          personalizationAppliedToAllPersonalizableProducts:
            action.payload.data.shoot
              .personalization_applied_to_all_personalizable_products,
          postpayRequiresSameValuePersonalization:
            action.payload.data.shoot
              .postpay_requires_same_value_personalization,
          prepayRequiresSameValuePersonalization:
            action.payload.data.shoot
              .prepay_requires_same_value_personalization,
          photoEnhancements: action.payload.data.photo_enhancements,
          requiresYearbookSelection:
            action.payload.data.shoot.requires_yearbook_selection,
          shoot: action.payload.data.shoot,
          shootPricing: action.payload.data.shoot_pricing,
          shootProducts: action.payload.data.shoot_products,
          suggestedProducts: action.payload.data.suggested_products,
          suggestedProductsLinkToIndex:
            action.payload.data.suggested_products_link_to_index,
          yearbookSelectionDeadline:
            action.payload.data.shoot.yearbook_selection_deadline || null,
          yearbookSelectionInstructions:
            action.payload.data.shoot.yearbook_selection_instructions || null,
          yearbookBackgroundOptions:
            action.payload.data.shoot.yearbook_background_options || [],
          yearbookBackgroundSelectionType:
            action.payload.data.shoot.yearbook_background_selection_type ||
            null,
          yearbookFixedBackground:
            action.payload.data.shoot.yearbook_fixed_background || null,
        };
      }
      return state;

    case FORMAT_AND_SET_BACKGROUND_OPTIONS:
      return { ...state, formattedBackgroundOptions: action.payload };
    case FORMAT_AND_SET_INCENTIVE_PRODUCTS:
      return { ...state, formattedIncentiveProducts: action.payload };
    case SET_RETOUCHING_PRODUCT:
      return { ...state, retouchingProduct: action.payload };
    case SET_RETOUCHING_PRODUCTS:
      return { ...state, retouchingProducts: action.payload };
    case SET_PERSONALIZATION_PRODUCT:
      return { ...state, personalizationProduct: action.payload };
    case SET_INTERSTITIAL_PRODUCTS:
      return { ...state, interstitialProducts: action.payload };
    case SET_PRE_CART_OFFER_PRODUCTS:
      return { ...state, preCartOfferProducts: action.payload };
    case SET_TRIGGERS_PRE_CART_OFFER_PRODUCTS:
      return { ...state, triggersPreCartOfferProducts: action.payload };
    default:
      return state;
  }
}
