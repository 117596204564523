import axios from 'axios';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { setAdditionalData } from '../../../actions/additionalData';
import useModalCarousel from '../hooks/modalCarousel';
import ModalCarousel from '../modalCarousel/ModalCarousel';
import {
  AlertText,
  Header,
  PrimaryButton,
  RedItalicText,
  SecondaryButton,
  Text,
  Wrapper,
} from '../v3Styles';
import {
  CarouselImg,
  CarouselImgWrapper,
  CategoryWrapper,
  Grid,
  Image,
  ImageButton,
  ImageWrapper,
  ZoomIcon,
} from './yearbookStyles';
import { selectYearbookPoses } from '../../../utils/yearbook';

const SelectYearbookPhoto = ({ history }) => {
  const [selectedImageId, setSelectedImageId] = useState(null);
  const dispatch = useDispatch();
  const { open, closeModal, openModal, slideIndex, setSlideIndex } =
    useModalCarousel();
  const studentGallery = useSelector(
    (state) => state.additionalData.studentGallery,
  );
  const shoot = useSelector((state) => state.shoot);
  const isFixedBg = shoot.yearbookBackgroundSelectionType === 'fixed_selection';
  const currentGalleryId = useSelector(
    (state) => state.additionalData.galleryThatInitiatedOrder.galleryId,
  );
  const yearbookPoses = selectYearbookPoses();
  const poses = yearbookPoses.length > 0 ? yearbookPoses : studentGallery;

  const updateYearbookImage = async (imageId) => {
    try {
      const body = {
        yearbook_selection: true,
      };
      if (shoot.yearbookBackgroundSelectionType === 'fixed_selection') {
        body.yearbook_background_id = shoot.yearbookFixedBackground.id;
      }
      const response = await axios.patch(
        `/api/v2/yearbook/images/${selectedImageId || imageId}`,
        body,
      );
      if (response.status >= 200 && response.status < 300) {
        history.push('/v3/yearbook-selection-complete');
      } else {
        throw new Error('Something went wrong');
      }
    } catch (error) {
      console.error(error);
      throw new Error('Something went wrong');
    }
  };

  const handleImageClick = (image) => () => {
    dispatch(setAdditionalData({ currentGalleryImageId: image.id }));
    setSelectedImageId(image.id);
    if (shoot.yearbookBackgroundSelectionType !== 'custom_selection') {
      Swal.fire({
        html: `
          <p class="header">
            You have made your yearbook pose selection
          </p>
          <p class="red italic">
            ${
              shoot.yearbookSelectionInstructions
                ? shoot.yearbookSelectionInstructions
                : ''
            }
          </p>
          <p>
            If this is not the pose you wish to have in the yearbook, please go back and change your selection
          </p>
          <div class="img-wrapper">
            <img class="bg" src="${
              shoot.yearbookBackgroundSelectionType === 'fixed_selection'
                ? shoot.yearbookFixedBackground.thumbnail_url
                : ''
            }"/>
            <img src="${image.url}"/>
          </div>
        `,
        confirmButtonText: 'Select and Continue',
        showCancelButton: true,
        customClass: {
          popup: 'popup',
          confirmButton: 'confirm-button',
          cancelButton: 'cancel-button',
        },
        preConfirm: () => updateYearbookImage(image.id),
      });
    } else {
      history.push('/v3/yearbook-background-selection');
    }
  };

  const handleSkip = async () => {
    try {
      const response = await axios.patch(
        `/api/v2/yearbook/galleries/${currentGalleryId}`,
        {
          skip_yearbook: true,
        },
      );
      if (response.status >= 200 && response.status < 300) {
        history.push('/dashboard');
      } else {
        throw new Error('Something went wrong');
      }
    } catch (error) {
      console.error(error);
      throw new Error('Something went wrong');
    }
  };

  const handleSkipPress = () => {
    Swal.fire({
      html: `
        <p class="header">
          <span class="red">Please confirm:</span> you do not wish to select any of the photos from this gallery.
        </p>
      `,
      showDenyButton: true,
      denyButtonColor: '#0B76B7',
      denyButtonText: 'Nevermind, go back',
      confirmButtonText: 'Yes, bypass selection',
      customClass: {
        popup: 'popup',
        confirmButton: 'button--secondary',
        denyButton: 'deny-button',
      },
      preConfirm: handleSkip,
    });
  };

  const handleZoomClick = (index) => () => {
    setSlideIndex(index);
    openModal();
  };

  return (
    <Wrapper>
      <Header>Yearbook Pose Selection</Header>
      <Text>
        Please select the photo you would like to be used as your photo in the
        yearbook
      </Text>
      <RedItalicText>{shoot.yearbookSelectionInstructions}</RedItalicText>
      <AlertText>{`Yearbook poses must be selected by ${moment(
        shoot.yearbookSelectionDeadline,
      ).format('MMMM DD, YYYY')}`}</AlertText>
      <CategoryWrapper>
        <Grid>
          {poses.map((image, index) => {
            const selected = selectedImageId === image.id;
            return (
              <ImageWrapper key={image.id} selected={selected}>
                <Image
                  background={
                    isFixedBg
                      ? shoot.yearbookFixedBackground.thumbnail_url
                      : null
                  }
                  src={image.url}
                />
                <ZoomIcon onClick={handleZoomClick(index)} />
                <ImageButton onClick={handleImageClick(image)}>
                  Select pose
                </ImageButton>
              </ImageWrapper>
            );
          })}
        </Grid>
      </CategoryWrapper>
      <p>Don't want to select any of these photos?</p>
      <SecondaryButton onClick={handleSkipPress}>
        Skip Yearbook Pose Selection
      </SecondaryButton>

      <ModalCarousel open={open} onClose={closeModal} selectedItem={slideIndex}>
        {poses.map((image) => (
          <CarouselImgWrapper key={image.id}>
            <CarouselImg
              background={
                isFixedBg ? shoot.yearbookFixedBackground.thumbnail_url : null
              }
              src={image.url}
            />
            <PrimaryButton onClick={handleImageClick(image)}>
              Select pose
            </PrimaryButton>
          </CarouselImgWrapper>
        ))}
      </ModalCarousel>
    </Wrapper>
  );
};

SelectYearbookPhoto.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default SelectYearbookPhoto;
