import {
  SET_ADDITIONAL_DATA,
  RESET_ADDITIONAL_DATA,
} from '../actions/additionalData';

const INITIAL_STATE = {
  keySubmittedByUser: null,
  picsGalleryId: null,
};

export default function (state = INITIAL_STATE, action) {
  Object.freeze(state);

  switch (action.type) {
    case SET_ADDITIONAL_DATA:
      return { ...state, ...action.payload };
    case RESET_ADDITIONAL_DATA:
      return {};
    default:
      return state;
  }
}
