import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import formatUSD from '../../../utils/formatUSD';

const RowContainer = styled.div`
  display: flex;
  flex-direction: column; // Changed to column to stack child elements vertically
`;

const Image = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 100px;
  height: 125px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
`;

const ImageContent = styled.img`
  display: block;
  width: 100%;
  height: auto;
`;

const Name = styled.div`
  ${'' /* font-style: italic; */}
  color: #4a4a4a;
  padding-top: 8px;
  font-size: 13px;
  text-align: center;
`;

const Price = styled.div`
  color: #4a4a4a;
  padding-top: 8px;
  font-size: 13px;
  text-align: center;
`;

const ImagePairColumn = styled.div`
  display: flex;
  margin-bottom: 16px; // Added bottom margin

  &:last-child {
    margin-bottom: 0; // Remove bottom margin for the last element
  }
`;

const SingleImageColumn = styled.div`
  margin-right: 20px;
`;

const CartItemBackgroundsPoses = ({ cartItem, orderAdditionalData }) => {
  const { backgroundOptions } = useSelector((state) => state.shoot);
  const { isGeskus, isPostEvent, studentPreviewImageUrl } = orderAdditionalData;
  const { poses } = cartItem;
  const pose = poses?.length > 0 ? poses[0] : null;

  const findBackgroundImage = (jsonObj, imageId) => {
    for (const obj of jsonObj) {
      const image = obj.background_images.find((img) => img.id === imageId);
      if (image) {
        return image;
      }
    }
    return null;
  };

  const renderBackgrounds = () => {
    const { backgroundSelections } = cartItem;

    const backgroundsList = cartItem.backgroundIds.map(
      (backgroundId, index) => {
        const background = findBackgroundImage(backgroundOptions, backgroundId);

        let pose = null;
        let multiConfigChildProduct = null;

        if (cartItem && Array.isArray(poses) && poses.length > index) {
          pose = poses[index];
          multiConfigChildProduct = cartItem.multiConfigChildProducts?.[index];
        }

        if (!((pose && !isGeskus) || background)) {
          return null;
        }

        let backgroundPrice = 0; // Default value for backgroundPrice

        // Check if backgroundSelections exists and is a non-empty array
        if (backgroundSelections && backgroundSelections.length > 0) {
          // Find the object in backgroundSelections where id matches backgroundId
          const selection = backgroundSelections.find(
            (selection) => selection.id === backgroundId,
          );

          // If a matching selection is found, set backgroundPrice to its price
          if (selection) {
            backgroundPrice = selection.price;
          }
        }

        let studentPoseUrl = null;

        console.log(
          'determining studentPoseUrl based on the value of pose and isGeskus and isPostEvent and studentPreviewImageUrl',
        );

        if (pose) {
          studentPoseUrl = poses[index]?.url;
        } else {
          if (isGeskus && isPostEvent && studentPreviewImageUrl) {
            studentPoseUrl = studentPreviewImageUrl;
          }
        }

        return (
          <ImagePairColumn
            key={`cart-background-option-${backgroundId}-${index}`}
          >
            <SingleImageColumn>
              {multiConfigChildProduct && (
                <Name>{multiConfigChildProduct.name}</Name>
              )}
              {/* <Name>{background.display_name}</Name> */}
              <Image
                style={{
                  backgroundImage: `url("${background.image_thumbnail}")`,
                }}
                onContextMenu={(event) => event.preventDefault()}
              >
                {studentPoseUrl && <ImageContent src={studentPoseUrl} />}
              </Image>
              {backgroundPrice > 0 && (
                <Price>{formatUSD(backgroundPrice)}</Price>
              )}
            </SingleImageColumn>
          </ImagePairColumn>
        );
      },
    );

    return backgroundsList;
  };

  const renderPoses = () => {
    return cartItem.poses.map((pose, index) => {
      if (!pose) {
        return null;
      }

      return (
        <ImagePairColumn key={`cart-background-option-${poses.id}-${index}`}>
          <SingleImageColumn>
            <Image
              onContextMenu={(event) => event.preventDefault()}
              style={{
                backgroundImage: `url("${poses[index].url}")`,
              }}
            />
            {/* <Name>{pose.name}</Name> */}
          </SingleImageColumn>
        </ImagePairColumn>
      );
    });
  };

  const renderGeskusPreviewImageOnly = () => {
    if (cartItem?.posesCount > 0 && studentPreviewImageUrl) {
      return (
        <ImagePairColumn key={`cart-background-option-1`}>
          <SingleImageColumn>
            <Image
              onContextMenu={(event) => event.preventDefault()}
              style={{ backgroundImage: `url("${studentPreviewImageUrl}")` }}
            />
          </SingleImageColumn>
        </ImagePairColumn>
      );
    }

    return null;
  };

  const renderBackgroundsPoses = () => {
    if (cartItem.backgroundIds && cartItem.backgroundIds.length > 0) {
      return renderBackgrounds();
    }

    if (cartItem.poses && cartItem.poses.length > 0) {
      return renderPoses();
    }

    if (isGeskus && isPostEvent && studentPreviewImageUrl) {
      return renderGeskusPreviewImageOnly();
    }

    return null;
  };

  return <RowContainer>{renderBackgroundsPoses()}</RowContainer>;
};

export default CartItemBackgroundsPoses;
