import React, { Component } from 'react';
import styled from 'styled-components';

const LandingWrapper = styled.div`
  padding-top: 25px;
  @media (max-width: 992px) {
    padding-top: 0;
  }
`;

const Logo = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 160px;
  height: 160px;
  background-image: url('https://s3.amazonaws.com/pics-assets/logos/sjp-logo-black.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  @media (max-width: 992px) {
    height: 90px;
    width: 100%;
  }
`;

const Header = styled.h1`
  margin-top: 30px;
`;

const LandingSportsJournal = ({ history }) => {
  const centerStyle = {
    margin: '0 auto',
    float: 'none',
  };

  return (
    <LandingWrapper className="gb-landing">
      <div className="gb-landing__top clearfix">
        <div className="gb-landing__top__left">
          <Logo alt="Adirondack School Portraits Logo" />
          <Header className="gb-landing__top__left__header">Welcome</Header>
          <h3 className="gb-landing__top__left__subheader">
            To Sports Journal Photos online ordering!
          </h3>
          <h3 className="gb-landing__top__left__subheader">
            For the security of your child, you must create an account to place
            an order.
          </h3>
          <div
            className="gb-landing__signup-button cursor-pointer"
            onClick={() => history.push('/account-lookup', { step: 1 })}
          >
            Place Your Order
          </div>
        </div>
        <img
          className="gb-landing__top__image"
          src="https://s3.amazonaws.com/pics-assets/landing/gb_main.png"
        />
      </div>
      <div className="gb-landing__center">
        <h2 className="gb-landing__center__header">Online Exclusives</h2>
        <div className="clearfix">
          {/* <div className="gb-landing__center__left">
              <div className="gb-landing__center__box-header">
                More Backgrounds
              </div>
              <div className="gb-landing__center__box-desc">
                Order online and get access to over 150 exciting backgrounds you
                won't find on our flyer!
              </div>
            </div> */}
          <div className="gb-landing__center__right" style={centerStyle}>
            <div className="gb-landing__center__box-header">
              Pay with Credit Card
            </div>
            <div className="gb-landing__center__box-desc">
              No more stuffing checks or cash into paper envelopes. Pay securely
              with your credit card.
            </div>
          </div>
        </div>
      </div>
      <div className="gb-landing__bottom clearfix">
        <div className="gb-landing__bottom__left">
          <h2 className="gb-landing__bottom__left__header">
            Print or Digital, Quality first.
          </h2>
          <div className="gb-landing__bottom__left__desc">
            It starts with capturing a great photo, but it doesn't end there.
            Quality products and great customer service are the cornerstones of
            what we do.
          </div>
        </div>
        <img
          className="gb-landing__bottom__right"
          src="https://s3.amazonaws.com/pics-assets/landing/gb_phone.png"
        />
      </div>
    </LandingWrapper>
  );
};

export default LandingSportsJournal;
