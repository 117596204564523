import React from 'react';
import CartItem from './CartItem';
import cartUtils from '../../../utils/cart';

const CartItemsList = ({ order, studentId }) => (
  <>
    {cartUtils.sortCartItems(order.cartItems).map((cartItem, index, array) => (
      <CartItem
        key={`cart-item-${order.student.id}-${cartItem.id}`}
        cartItem={cartItem}
        studentId={studentId}
        order={order}
        isLastItem={index === array.length - 1}
      />
    ))}
  </>
);

export default CartItemsList;
