/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addToCart } from '../../../actions/v3Order';
import MultiConfig from './multi-config/MultiConfig';
import RegularProductConfig from './regular/RegularProductConfig';

// TODO: remove this componet when testing is complete

const ProductConfig = (props) => {
  const { match, shoot } = props;

  const productId = match.params.id;

  // TODO: move this into a util
  const matchingCategory = shoot.shootProducts.find((category) =>
    category.products.some((product) => product.id.toString() === productId),
  );

  if (matchingCategory) {
    const product = matchingCategory.products.find(
      (prod) => prod.id.toString() === productId,
    );

    if (product.is_multi_config) {
      return <MultiConfig product={product} />;
    }

    // TODO: handle incentive and nth-free products here?

    return <RegularProductConfig product={product} />;
  }
  return (
    <div>
      <h1>Product Config</h1>
      <p>Product not found</p>
    </div>
  );
};

const mapStateToProps = (state) => ({
  additionalData: state.additionalData,
  shoot: state.shoot,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addToCart,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductConfig);
