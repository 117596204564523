import React, { useState } from 'react';
import styled from 'styled-components';
import formatUSD from '../../../../../utils/formatUSD';
import { render } from 'react-dom';

const PosesList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: -12px; // Compensate for padding on the items

  @media (max-width: 768px) {
    // Tablet and below breakpoint
    flex-direction: column;
    align-items: center;
  }
`;

const PoseItem = styled.li`
  flex: 1 0 calc(25% - 24px); // 25% for 4-across grid minus gutter
  max-width: calc(25% - 24px); // Ensure 4-across grid always
  padding: 12px; // Half of the gutter
  cursor: pointer;

  @media (max-width: 768px) {
    // Adjustments for mobile
    flex: 1 0 50%; // Take 50% of the container width
    max-width: 50%; // Make sure it doesn't exceed 50%
    margin: 0 auto; // Center the item in the column
  }

  @media (min-width: 481px) and (max-width: 768px) {
    // Between these widths, 2-across grid
    flex: 1 0 calc(50% - 24px); // 50% for 2-across grid minus gutter
    max-width: calc(50% - 24px);
  }

  img {
    max-width: 100%;
    height: auto; // Preserve aspect ratio
    aspect-ratio: 8/10;
    background-color: white;
    border: 1px solid lightgrey;
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.1);
  }
`;

const BackgroundsPosesSelector = ({
  addBackgroundSelection,
  addPoseSelection,
  allAvailablePosesForOrder,
  availablePoses,
  backgroundOptions,
  backgroundsPosesSelectorVisible,
  backgroundSelections,
  isPrepay,
  posesCount,
  poseSelectionIds,
  poseSelections,
  productBackgroundOptionsCount,
  studentGallery,
}) => {
  const [backgroundsCategoryIndex, setbackgroundsCategoryIndex] = useState(0);

  console.log('BackgroundsPosesSelector');

  if (!productBackgroundOptionsCount || productBackgroundOptionsCount === 0) {
    return null;
  }

  const BackgroundPrice = (background) => {
    if (Number.isInteger(background?.price) && background.price > 0) {
      return (
        <>
          <br />
          <span className="bnl-bg-price">{formatUSD(background.price)}</span>
        </>
      );
    }

    return null;
  };

  const renderBackgroundCategoryTabs = () => {
    return backgroundOptions.map((backgroundCategory, index) => {
      let activeClass = '';

      if (index === backgroundsCategoryIndex) {
        activeClass = 'group-active';
      }

      return (
        <li
          key={backgroundCategory.id}
          className={`bnl-product-config__bg-selector__tab ${activeClass}`}
          onClick={() => setbackgroundsCategoryIndex(index)}
        >
          {backgroundCategory.name}
        </li>
      );
    });
  };

  const renderBackgroundOptions = () => {
    return backgroundOptions[backgroundsCategoryIndex]?.background_images?.map(
      (background) => {
        const backgroundOptionStyle = {
          backgroundImage: `url(${background.image_thumbnail})`,
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        };

        const lastPose =
          poseSelections.length > 0
            ? poseSelections[poseSelections.length - 1]
            : null;

        return (
          <li
            key={background.id}
            className="bnl-product-config__bg-container col-lg-2"
            onClick={() => addBackgroundSelection(background)}
          >
            <div
              className="bnl-product-config__bg bnl-product-config__bg--b  cursor-pointer"
              style={backgroundOptionStyle}
            >
              {lastPose && (
                <img
                  src={lastPose.url}
                  alt="Overlay Pose"
                  onContextMenu={(event) => event.preventDefault()}
                  style={{
                    width: '100%', // Ensuring the overlay image covers the background
                    height: '100%',
                    objectFit: 'cover',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                  }}
                />
              )}
            </div>
            <div className="bnl-bg-text text-center">
              {background.display_name}
              {BackgroundPrice(background)}
            </div>
          </li>
        );
      },
    );
  };

  const renderPoses = () => {
    // Prioritize availablePoses when posesCount > 1, otherwise prioritize non-empty studentGallery, fallback to availablePoses if studentGallery is empty or non-existent.
    let poseSource =
      posesCount > 1
        ? availablePoses
        : studentGallery && studentGallery?.length > 0
        ? studentGallery
        : availablePoses;

    if (allAvailablePosesForOrder?.length > 0) {
      poseSource = allAvailablePosesForOrder;
    }

    const poses = poseSource?.map((pose) => {
      // TODO: handle group image type
      return (
        <PoseItem
          key={pose.id}
          onClick={() => {
            addPoseSelection(pose.id);
          }}
        >
          <img
            src={pose.url}
            onContextMenu={(event) => event.preventDefault()}
          />
        </PoseItem>
      );
    });

    return <PosesList>{poses}</PosesList>;
  };

  const renderPosesConfig = () => {
    // console.log('RENDER POSES CONFIG');
    // console.log(backgroundOptions);
    // console.log('====');

    if (
      !isPrepay &&
      posesCount > 0 &&
      (availablePoses || studentGallery) &&
      (poseSelectionIds.length === backgroundSelections.length ||
        !backgroundOptions.length)
    ) {
      return <div className="bnl-product-config__poses">{renderPoses()}</div>;
    }
    return null;
  };

  const renderBackgroundsConfig = () => {
    // console.log('RENDER BACKGROUND CONFIG');
    if (
      isPrepay ||
      (!isPrepay && !availablePoses && !studentGallery) ||
      (posesCount > 0 && backgroundSelections.length < poseSelectionIds.length)
    ) {
      return (
        <div className="bnl-product-config">
          <ul className="bnl-product-config__bg-selector__tabs-container">
            {renderBackgroundCategoryTabs()}
          </ul>
          <ul className="row equal-height">{renderBackgroundOptions()}</ul>
        </div>
      );
    }

    return null;
  };

  return (
    <div
      className={`backgroundsPosesContainer ${
        backgroundsPosesSelectorVisible ? 'element-visible' : 'element-hidden'
      }`}
    >
      {renderPosesConfig()}
      {renderBackgroundsConfig()}
    </div>
  );
};

export default BackgroundsPosesSelector;
